import CookieBanner from '@/components/CookieBanner';
import Footer from '@/components/Footer';
import Seo from '@/components/Seo';
import { graphql, Link, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import LoginIcon from '../assets/icons/login.svg';
import Logo from '../assets/icons/logo.svg';
import MenuIcon from '../assets/icons/menu.svg';
import '../styles/index.scss';

const Imprint: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [showCookieBanner, toggleCookieBanner] = useState<boolean>(true);
  const [showMobileMenu, toggleShowMobileMenu] = useState<boolean>(false);
  const [hackIndex, setHackIndex] = useState<number>(0);
  const cookieName: string = process.env.GATSBY_GOOGLE_COOKIE_NAME!;
  const [cookies] = useCookies([cookieName]);

  // this is used to counter the behaviour that on mount the document
  // is not defined and the parallax does not trigger
  useEffect(() => {
    setTimeout(() => {
      setHackIndex((old) => old + 1);
    }, 1500);
    toggleCookieBanner(cookies[cookieName] !== 'true');
  }, []);

  return (
    <div className="content-wrapper">
      <Seo />
      {showCookieBanner && (
        <CookieBanner
          onDecline={() => toggleCookieBanner(false)}
          onAccept={() => toggleCookieBanner(false)}
        />
      )}
      <div className="background-curve-top sub-page" />
      <div className="header">
        <MenuIcon
          className="menu hide-on-big"
          onClick={() => toggleShowMobileMenu(true)}
        />
        <Link to="/" className="logo-with-name">
          <Logo />
          <div className="hide-on-small">{t('appName')}</div>
        </Link>
        {showMobileMenu && (
          <div
            className="clickaway"
            onClick={() => toggleShowMobileMenu(false)}
          />
        )}
        <div
          className={`menu-wrapper ${!showMobileMenu && 'disappear'}`}
          onClick={() => toggleShowMobileMenu(false)}
        >
          <a href="/#stempl" className={`menu`}>
            {t('appName')}
          </a>
          <a href="/#configurate" className={`menu`}>
            {t('menu.configuration')}
          </a>
          <a href="/#prices" className={`menu`}>
            {t('menu.prices')}
          </a>
          <span className="login-icon">
            {showMobileMenu && <p>{t('menu.login')}</p>}
            <LoginIcon onClick={() => navigate(process.env.GATSBY_APP_URL!)} />
          </span>
        </div>
      </div>
      <div id="stempl" className="stempl-wrapper sub-page">
        <div className="section-info">
          <div className="stempl-heading">{t('imprint.title')}</div>
        </div>
      </div>

      <div className="introduction-wrapper" key={`hack-index-${hackIndex}`}>
        <div className="content-box">
          <p>
            <strong>DevDuck GmbH</strong>
            <br />
            Leitzstraße 45
            <br />
            70469 Stuttgart
          </p>
          <p>
            Telefon +49 (0) 711 490 66 461
            <br />
            E-Mail <a href="info@devduck.de">info@devduck.de</a>
            <br />
            Geschäftsführer Pascal Faber, Maximilian Wüst
          </p>
          <p>
            Amtsgericht Stuttgart
            <br />
            Registernummer HRB 772195
            <br />
            Ust.-ID-Nr. DE328719918
          </p>
          <div className="spacing" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Imprint;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
